<template>
  <div>
    <label v-if="label">{{label}}</label>
    <div class="mad-lib-container">
      <mad-lib-select :value="secureProtocolValue"
                      class="mr-3"
                      :options="[{text: 'https://', value: true}, {text: 'http://', value: false}]"
                      @input="secureProtocolValue = $event; updateSecureProtocol()" />
      <basic-mad-lib-input :value="domainValue"
                           fill-width
                           @input="domainValue = $event; updateDomain($event)" />
    </div>
    <small v-if="description" class="text-muted">{{description}}</small>
  </div>
</template>

<script>
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'

export default {
  name: 'domainInput',
  components: { BasicMadLibInput, MadLibSelect },
  props: {
    label: String,
    description: String,
    value: String, // set the v-model/value prop to the entire webaddress (useful for creative/adgroup destination urls kind of thing)
    domain: String, // set the domain and secure protocol props to the domain and secure protocol values (useful when the two items are split as in accountPlan)
    secureProtocol: {
      type: Boolean,
      default: true
    }, // listen for @input, or (@domain and @secure). listening to both will likely give you a double update,
    validityCallback: Function, // optionally, provide your own validation callback in addition to the validity checks in this component, function should accept one argument has the string to validate, and return true if valid
    invalidError: String // optionally, provide your own invalid error message
  },
  data () {
    return {
      domainValue: this.domain, // set via the watcher below
      secureProtocolValue: this.secureProtocol
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          if (newValue.startsWith('http')) {
            this.secureProtocolValue = newValue.includes('https://')
            this.domainValue = newValue.replace('https://', '').replace('http://', '')
            // this.updateValue()
            this.$emit('secure', this.secureProtocolValue) // doing these directly instead of using the update helper methods to avoid double value updates
            this.$emit('domain', this.domainValue)
          } else {
            this.updateDomain(newValue)
          }
        }
      },
      immediate: true
    },
    domain: {
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.$setCompat(this, 'domainValue', newValue)
        }
      },
      immediate: true
    },
    secureProtocol: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$setCompat(this, 'secureProtocolValue', newValue)
        }
      },
      immediate: true
    }
  },
  computed: {
    protocolString () {
      return this.secureProtocolValue ? 'https://' : 'http://'
    },
    isValidDomain () {
      return this.isLocalValidDomain && this.isCallbackValidDomain
    },
    isLocalValidDomain () {
      return this.domainValue &&
        !this.domainValue.startsWith('http') &&
        !this.domainValue.startsWith(':') &&
        !this.domainValue.startsWith('//')
    },
    isCallbackValidDomain () {
      let callbackValid = true
      if (this.validityCallback) {
        callbackValid = this.validityCallback(this.domainValue)
      }
      return callbackValid
    },
    invalidErrorMessage () {
      if (!this.isLocalValidDomain) {
        return 'Avoid entering http:// or https://'
      } else if (!this.isCallbackValidDomain) {
        return this.invalidError ?? 'The url you entered is invalid. If you believe this is incorrect, please contact Fluency.'
      }
      return 'Hmmm. Something isn\'t right'
    }
  },
  methods: {
    updateSecureProtocol () {
      this.$emit('secure', this.secureProtocolValue)
      this.updateValue()
    },
    updateDomain (newDomain) {
      this.domainValue = newDomain
      if (this.isValidDomain) {
        this.$emit('domain', newDomain)
        this.updateValue()
      }
    },
    updateValue () {
      this.$emit('input', this.protocolString + this.domainValue)
    }
  }
}
</script>

<style scoped>

</style>
